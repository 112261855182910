import styled from "@emotion/styled";

const ReasonHolder = styled.div`
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    padding: 1rem 1.5rem;
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    font-size: 1.2vw;
    padding: 1vw 1.5vw;
  }
`;

export default ReasonHolder;
