import React from "react";
import Container from "./styles/Container";
import UpperSection from "./styles/UpperSection";
import LowerSection from "./styles/LowerSection";
import ButtonContainer from "./styles/ButtonContainer";
import Button from "./styles/Button";
import TextContainer from "./styles/TextContainer";
import GreenCheckIcon from "assets/Others/GreenCheckIcon";
import CrossButton from "assets/Others/CrossButton";
import CrossButtonContainer from "./styles/CrossButtonContainer";
import { useTheme } from "@emotion/react";

const YOUTUBE_VIDEO_ON_COMPLETION =
  "https://www.youtube.com/watch?v=Cf1SxvvXGak";

const SuccessModal = ({ onCrossClick, modalText }: Props) => {
  const onOkClickHandler = () => {
    window.open(YOUTUBE_VIDEO_ON_COMPLETION, "blank");
    onCrossClick();
  };
  const theme = useTheme() as any;

  return (
    <Container>
      <UpperSection theme={theme}>
        <CrossButtonContainer onClick={onCrossClick}>
          <CrossButton />
        </CrossButtonContainer>
        <GreenCheckIcon />
      </UpperSection>
      <LowerSection>
        <TextContainer>
          {modalText ||
            "Thank you for submitting your details! Our loan advisors will get back to you soon"}
        </TextContainer>
      </LowerSection>
      <ButtonContainer onClick={onOkClickHandler}>
        <Button theme={theme}>OK</Button>
      </ButtonContainer>
    </Container>
  );
};

interface Props {
  onCrossClick: () => void;
  modalText: string;
}

export default SuccessModal;
