import styled from '@emotion/styled'

interface Props {
  large?: boolean
}
const Wrapper = styled.div<Props>`
  flex: ${({ large }) => (large ? '1.5' : '1')};
  @media (min-width: 768px) {
    padding-right: ${({ large }) => (large ? '2rem' : '0px')};
  }
`

export default Wrapper
