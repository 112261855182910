const MinimalDocumentation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#CE9F29"
              fillOpacity=".12"
              d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20z"
              transform="translate(-759 -2001) translate(0 1874) translate(760 128)"
            />
            <path
              stroke="#CE9F29"
              strokeDasharray="40 4"
              d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20"
              transform="translate(-759 -2001) translate(0 1874) translate(760 128)"
            />
            <g>
              <path
                d="M0 0H24V24H0z"
                transform="translate(-759 -2001) translate(0 1874) translate(760 128) translate(8 8)"
              />
              <path
                fill="#CE9F29"
                fillRule="nonzero"
                d="M15 3H5c-1.105 0-2 .895-2 2v14c0 1.105.895 2 2 2h14c1.105 0 2-.895 2-2V9l-6-6m4 16H5V5h9v5h5m-2 4H7v-2h10m-3 5H7v-2h7"
                transform="translate(-759 -2001) translate(0 1874) translate(760 128) translate(8 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MinimalDocumentation;
