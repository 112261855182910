import styled from "@emotion/styled";

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    & > img {
      width: calc(100vw);
    }
  }
  @media (min-width: 768px) {
    & > img {
      width: calc(46vw - 5.6rem);
    }
  }
`;

export default ImageWrapper;
