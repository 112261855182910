import { createStore, applyMiddleware, Store } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer, { AppState } from "reducers";

import AppActions from "types/actions";

const store = (): Store<AppState> => {
  const composeEnhancers = composeWithDevTools({});

  return createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
    )
  );
};

export default store;
