import styled from "@emotion/styled";

const ImageHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  & > img {
    height: 3.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 768px) {
    width: calc((100% - 4rem / 3));
    & > img {
      // width: 80%;
      height: 4vw;
    }
  }
`;

export default ImageHolder;
