import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}

const Container = styled.footer<Props>`
  min-height: 20vh;

  background: #fafafa;

  display: flex;
  flex-direction: column;

  padding: 1rem;
  /* margin-bottom: 3vh; */

  border-top: ${({ theme }) => `solid 1px ${theme.background.itemBounds}`};

  @media (min-width: 768px) {
    padding-left: 10%;
    padding-right: 10%;
    flex-direction: row;

    min-height: 344px;
    padding-top: 2.5rem;

    border: ${({ theme }) => `solid 1px ${theme.background.itemBounds}`};
  }
`;

export default Container;
