import Axios from "axios";
import { ErrorResponseHandler } from "../index";
import { SuccessResponse, ErrorResponse } from "types/api";

const createLead = async ({
  pincode,
  name,
  mobile,
  // company,
  utmCampaign,
  utmDetail,
  utmMedium,
  utmSource,
  utmTerm,
  utmContent,
}: any): Promise<SuccessResponse<any> | ErrorResponse> => {
  try {
    const response = await Axios.post(
      `${process.env.REACT_APP_LEAD_SERVICE_BASE_URL}/lead/landing-page`,
      {
        pincode,
        name,
        mobile,
        // company,
        utmCampaign,
        utmDetail,
        utmMedium,
        utmSource,
        utmTerm,
        utmContent,
      }
    );

    const result = response.data;

    if (response.status !== 200) {
      throw new Error(result.error.message);
    }

    return {
      statusCode: 200,
      message: "Success",
      payload: result.result,
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default createLead;
