import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme?: ThemeProps;
}

const Button = styled.button<Props>`
  height: 3.125rem;
  width: 100%;
  margin-bottom: 1rem;
  border: none;
  border-radius: 2px;
  font-size: 0.875rem;
  font-family: CircularStdBold;
  line-height: 1.14;
  letter-spacing: 0.5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 2px 8px 0 rgba(206, 159, 41, 0.2);
  background: ${({ theme }) => theme.color.primary};
`;

export default Button;
