export const pricipalAmount = 500000;

export const DEFAULT_RATE = 0.75;

export const CONSTANT_AMOUNT =
  ((DEFAULT_RATE * pricipalAmount) / 100 / 30) * 180 + pricipalAmount;

export const ANNUAL_APR = 21;

export const REPRESSENTATION_EXAMPLE =
  "Representative Example: If the loan amount is ₹100,000 and a customer selects indiagold plan with interest rate 9.24% per annum; & if the customer pays only the interest every 30 days for the next 180 days, then the overall calculated interest payable would be ₹4620 only (₹770 per month). So, Total cost of the loan would be (Principal + Interest): ₹1,04,620. The customers get the convenience to pay the principal balance anytime within 180 days tenure.";
