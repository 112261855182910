import styled from "@emotion/styled";

const LogoWrapper = styled.div`
  @media (max-width: 768px) {
    & > svg {
      height: 2rem;
    }
  }

  cursor: pointer;
`;

export default LogoWrapper;
