import styled from "@emotion/styled";

const InfoContainerWrapper = styled.div`
  padding: 2rem 10%;

  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

export default InfoContainerWrapper;
