import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}

const HeaderWrapper = styled.header<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 88px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  background: ${({ theme }) => theme.background.white};

  @media (min-width: 768px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export default HeaderWrapper;
