import styled from "@emotion/styled";

const CrossButtonContainer = styled.div`
  position: absolute;
  top: 8%;
  right: 3%;
  cursor: pointer;
`;

export default CrossButtonContainer;
