import styled from "@emotion/styled";

const MobileContainer = styled.div`
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`;

export default MobileContainer;
