import { ThemeProvider } from "@emotion/react";
import { ReactNode } from "react";
import theme from "./theme";
import GlobalStyles from "./GlobalStyle";

const Theme = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
