import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}
const Container = styled.div<Props>`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.background.goldLight};
    padding: 3rem 1rem 0 1rem;
  }

  @media (min-width: 768px) {
    padding: 2rem 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: ${({ theme }) => theme.background.goldLight};
  }
`;

export default Container;
