import styled from "@emotion/styled";

const StepContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    width: calc((80% - 9vw) / 3);
  }
`;

export default StepContainer;
