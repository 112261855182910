import styled from "@emotion/styled";

interface Props {
  desktop: string;
  mobile: string;
}

const ImageHolder = styled.img<Props>`
  @media (max-width: 768px) {
    content: url(${({ mobile }) => mobile});
  }

  @media (min-width: 768px) {
    content: url(${({ desktop }) => desktop});
  }
`;

export default ImageHolder;
