import { css, Global } from "@emotion/react";

const GlobalStyles = () => {
  return (
    <>
      <Global
        styles={css`
          * {
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            font-family: "CircularStd";
          }

          @font-face {
            font-family: "CircularStd";
            src: url("https://web-app-asset.s3.ap-south-1.amazonaws.com/CircularStd-Book.otf");
          }

          @font-face {
            font-family: CircularStdMedium;
            src: url("https://web-app-asset.s3.ap-south-1.amazonaws.com/CircularStd-Medium.otf");
          }

          @font-face {
            font-family: "CircularStdBold";
            src: url("https://web-app-asset.s3.ap-south-1.amazonaws.com/CircularStd-Bold.otf");
          }
        `}
      />
    </>
  );
};

export default GlobalStyles;
