import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  displays: boolean;
  theme?: ThemeProps;
}

const ErrorTextContainer = styled.div<Props>`
  @media (min-width: 768px) {
    font-size: 0.75vw;
  }
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.0333em;
  text-align: left;
  color: ${({ theme }) => theme.color.errorRed};
  display: ${({ displays }) => (!displays ? "none" : "block")};
`;

export default ErrorTextContainer;
