import styled from "@emotion/styled";

const RatingsContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

export default RatingsContainer;
