import styled from "@emotion/styled";

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
`;

export default HeaderWrapper;
