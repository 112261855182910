const QuickReleaseGuarantee = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#CE9F29"
              fillOpacity=".12"
              d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20z"
              transform="translate(-475 -2001) translate(0 1874) translate(476 128)"
            />
            <path
              stroke="#CE9F29"
              strokeDasharray="40 4"
              d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20"
              transform="translate(-475 -2001) translate(0 1874) translate(476 128)"
            />
            <g fillRule="nonzero">
              <g fill="#CE9F29">
                <path
                  d="M11 0H7c-.55 0-1 .45-1 1s.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1zM9 13c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm7.03-6.61l.75-.75c.385-.387.385-1.013 0-1.4l-.01-.01c-.387-.385-1.013-.385-1.4 0l-.75.75C13.027 3.698 11.044 3 9 3 4.2 3 .12 6.96 0 11.76c-.065 2.428.854 4.78 2.549 6.52C4.244 20.02 6.571 21.001 9 21c3.458.002 6.61-1.979 8.11-5.094 1.499-3.116 1.079-6.815-1.08-9.516zM9 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
                  transform="translate(-475 -2001) translate(0 1874) translate(476 128) translate(8 8) translate(3 1)"
                />
              </g>
              <path
                d="M0 0H24V24H0z"
                transform="translate(-475 -2001) translate(0 1874) translate(476 128) translate(8 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default QuickReleaseGuarantee;
