import React from "react";

import Container from "./styles/Container";
import Wrapper from "./styles/Wrapper";

import FirstBox from "./FirstBox";
import SecondaryBox from "./SecondBox";
import ThirdBox from "./ThridBox";
import ForthBox from "./ForthBox";
import { useTheme } from "@emotion/react";

const Footer = (): JSX.Element => {
  const theme = useTheme() as any;

  return (
    <Container theme={theme}>
      <Wrapper large>
        <FirstBox />
      </Wrapper>

      <Wrapper>
        <SecondaryBox />
      </Wrapper>

      <Wrapper>
        <ThirdBox />
      </Wrapper>

      <Wrapper>
        <ForthBox />
      </Wrapper>
    </Container>
  );
};

export default Footer;
