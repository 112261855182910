import styled from "@emotion/styled";

const ApplyNowButton = styled.button`
  max-width: 9.4rem;

  height: 2.5rem;
  background: #ce9f29;
  padding: 0.75rem 1rem;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(206, 159, 41, 0.2);
  border: none;
  font-size: 14px;
  font-family: CircularStdMedium;
  letter-spacing: 0.5px;
  width: 100%;
  cursor: pointer;

  @media (min-width: 768px) {
    max-width: 13.75rem;
  }
`;

export default ApplyNowButton;
