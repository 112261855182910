import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme?: ThemeProps;
}

export default styled.div<Props>`
  height: 0.25rem;
  width: 2.75rem;
  background: ${({ theme }) => theme.background.goldenDivider};

  margin: 0 auto;

  margin-bottom: 4rem;
`;
