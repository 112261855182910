import React from "react";

const Instagram = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g
          fill="#D03B70"
          transform="translate(-80 -1107) translate(80 1107) translate(3 3)"
        >
          <path d="M8.999 4.377c-2.554 0-4.624 2.07-4.624 4.624s2.07 4.624 4.624 4.624 4.624-2.07 4.624-4.624-2.07-4.624-4.624-4.624zm0 7.627c-1.66 0-3.004-1.345-3.004-3.004 0-1.66 1.345-3.004 3.004-3.004 1.66 0 3.004 1.345 3.004 3.004 0 1.66-1.345 3.004-3.004 3.004z" />
          <circle cx="13.806" cy="4.207" r="1.078" />
          <path d="M17.533 3.111C17.065 1.902 16.109.947 14.9.479c-.7-.263-1.439-.405-2.186-.42-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054c-.747.015-1.485.157-2.184.42C1.9.946.945 1.902.477 3.111c-.263.7-.404 1.439-.419 2.186-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187.469 1.209 1.425 2.164 2.634 2.632.697.273 1.436.425 2.185.45.963.042 1.268.055 3.71.055s2.755 0 3.71-.055c.747-.015 1.486-.157 2.186-.419 1.209-.469 2.164-1.424 2.633-2.633.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71c-.012-.758-.154-1.508-.421-2.217zm-1.218 9.532c-.006.576-.112 1.147-.311 1.688-.304.786-.925 1.408-1.712 1.711-.535.198-1.1.304-1.67.311-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055-.57-.007-1.135-.112-1.669-.311-.79-.301-1.414-.923-1.719-1.711-.197-.535-.302-1.1-.311-1.669-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655.006-.576.112-1.147.311-1.687.305-.789.93-1.41 1.719-1.712.535-.198 1.099-.303 1.669-.311.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055c.57.007 1.135.112 1.67.311.786.304 1.408.926 1.712 1.712.197.535.302 1.1.311 1.669.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011v-.001z" />
        </g>
        <path
          d="M0 0H24V24H0z"
          transform="translate(-80 -1107) translate(80 1107)"
        />
      </g>
    </g>
  </svg>
);

export default Instagram;
