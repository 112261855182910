import { DEFAULT_RATE } from "lib/constant";

export const extraInfo = [
  `*Interest rates starting at ${DEFAULT_RATE}% monthly. Terms & Conditions applied.`,
  // "*Zero foreclosure charges",
  "Our lending partner, Shivalik Small Finance Bank Ltd. is registered at 501, Salcon Aurum, Jasola District Centre, New Delhi, South Delhi, Delhi – 110025 CIN – U65900DL2020PLC366027",
];

export const address = [
  {
    name: `5th Floor, Holly Hocks Pvt Ltd, Plot #64, Sector 44, Gurgaon, Haryana, 122002.`,
  },
];
