export interface ThemeProps {
  color: {
    primary: string;
    text: string;
    headerColor: string;
    bodyBlack: string;
    lightGrey: string;
    grey: string;
    errorRed: string;
    success: string;
  };
  background: {
    goldenDivider: string;
    sectionSeparator: string;
    goldLight: string;
    extraGreyBG: string;
    white: string;
    itemBounds: string;
    successLight: string;
    scrim: string;
    lockerColor: string;
    success: string;
    errorLight: string;
    extraLightGrey: string;
  };
}

const Theme: ThemeProps = {
  color: {
    primary: "#ce9f29",
    text: "#121212",
    headerColor: "rgba(18, 18, 18, 0.5)",
    bodyBlack: "rgba(18, 18, 18)",
    lightGrey: "rgba(0,0,0,0.6)",
    grey: "rgba(18, 18, 18, 0.7)",
    errorRed: "#b00020",
    success: "#00b3a6",
  },
  background: {
    goldenDivider: "rgb(206, 159, 41)",
    sectionSeparator: "rgba(18, 18, 18, 0.05)",
    goldLight: "rgba(206,159,41,0.08)",
    extraGreyBG: "rgba(18,18,18,0.01)",
    white: "#ffffff",
    itemBounds: "#e2e2e2",
    successLight: "rgba(0,179,166,0.08)",
    scrim: "rgba(0,0,0,0.32)",
    lockerColor: "#4b2d7c",
    success: "#00b3a6",
    errorLight: "rgba(176,0,32, 0.08)",
    extraLightGrey: "#fcfcfc",
  },
};

export default Theme;
