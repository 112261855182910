import React from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import { AlertProps } from '@material-ui/lab/Alert/Alert'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import styled from './styles'

const ErrorSnackBar = ({
  open,
  setClose,
  message,
}: Props): JSX.Element => {
  const classes = styled()

  const handleClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setClose(false)
  }

  if (message === 'Invalid access token') {
    return <></>
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      message={message}
      classes={{
        anchorOriginBottomLeft: classes.message,
      }}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  )
}

interface Props {
  open: boolean
  setClose: React.Dispatch<React.SetStateAction<boolean>>
  message: string
  // eslint-disable-next-line react/no-unused-prop-types
  severity: AlertProps['severity']
}

export default ErrorSnackBar
