const LowestInterestRate = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#CE9F29"
              fillOpacity=".12"
              d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20z"
              transform="translate(-759 -1937) translate(0 1874) translate(760 64)"
            />
            <path
              stroke="#CE9F29"
              strokeDasharray="40 4"
              d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20"
              transform="translate(-759 -1937) translate(0 1874) translate(760 64)"
            />
            <g fillRule="nonzero">
              <g fill="#CE9F29">
                <path
                  d="M18.5 3.5l2 2-15 15-2-2 15-15M7 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3m10 10c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3M7 6c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1m10 10c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"
                  transform="translate(-759 -1937) translate(0 1874) translate(760 64) translate(8 8)"
                />
              </g>
              <path
                d="M0 0H24V24H0z"
                transform="translate(-759 -1937) translate(0 1874) translate(760 64) translate(8 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LowestInterestRate;
