import { AlertProps } from "@material-ui/lab/Alert";

// constant
export const ERROR_START = "ERROR_START";
export const ERROR_RESET = "ERROR_RESET";
export const SUCCESS_START = "SUCCESS_START";
export const SUCCESS_RESET = "SUCCESS_RESET";

export interface ErrorStartAction {
  type: typeof ERROR_START;
  payload: {
    errorMessage: string;
    severity?: AlertProps["severity"];
  };
}

export interface SuccessStartAction {
  type: typeof SUCCESS_START;
  payload: {
    successMessage: string;
    severity?: AlertProps["severity"];
  };
}

export interface ErrorResetAction {
  type: typeof ERROR_RESET;
}

export interface SuccessResetAction {
  type: typeof SUCCESS_RESET;
}

export type ErrorActions =
  | ErrorResetAction
  | ErrorStartAction
  | SuccessStartAction
  | SuccessResetAction;
