import styled from '@emotion/styled'

interface Props {
  color?: string
}

const Text = styled.a<Props>`
  color: ${({ color }) => color || '#54595f'};
  text-decoration: none;

  font-size: 0.8125rem;
`

export default Text
