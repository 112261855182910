const HighestGramPerRate = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#CE9F29"
                fillOpacity=".12"
                d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20z"
                transform="translate(-475 -1937) translate(0 1874) translate(476 64)"
              />
              <path
                stroke="#CE9F29"
                strokeDasharray="40 4"
                d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20"
                transform="translate(-475 -1937) translate(0 1874) translate(476 64)"
              />
              <g fillRule="nonzero">
                <g fill="#CE9F29">
                  <path
                    d="M11 2V0H0v2h3.5c1.302 0 2.401.838 2.815 2H0v2h6.315C5.895 7.195 4.767 7.996 3.5 8H0v2.414L5.586 16h2.828l-6-6H3.5c2.375-.003 4.42-1.674 4.898-4H11V4H8.398c-.153-.728-.47-1.412-.924-2H11z"
                    transform="translate(-475 -1937) translate(0 1874) translate(476 64) translate(8 8) translate(7 4)"
                  />
                </g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-475 -1937) translate(0 1874) translate(476 64) translate(8 8)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HighestGramPerRate;
