import { useTheme } from "@emotion/react";

import Heading from "../styles/Heading";
import IconContainer from "../styles/IconContainer";
import Text from "../styles/Text";
import HeadingContainer from "../styles/HeadingContainer";

const SecondaryBox = (): JSX.Element => {
  const theme = useTheme() as any;

  const Links: Links[] = [
    {
      name: "Gold Loans",
      url: "https://indiagold.co/gold-loan",
    },
    // {
    //   name: "Free Gold Locker",
    //   url: "https://indiagold.co/gold-locker",
    // },
    {
      name: "Digital Gold & Gold on EMI",
      url: "https://manage.indiagold.co/digital-gold",
    },
    // {
    //   name: 'Learn about Gold',
    //   // url: 'https://indiagold.co/blogs',
    // },
    {
      name: "Gold Loan Calculator",
      url: "https://indiagold.co/gold-loan#loan-calculator",
    },
  ];

  return (
    <>
      <HeadingContainer>
        <Heading theme={theme}>PRODUCTS</Heading>
      </HeadingContainer>

      {Links.map((link) => (
        <IconContainer theme={theme} key={link.name}>
          <Text href={link.url} target="_blank" color={theme.color.primary}>
            {link.name}
          </Text>
        </IconContainer>
      ))}
    </>
  );
};

interface Links {
  name: string;
  url: string;
}

export default SecondaryBox;
