import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}
const TextHolder = styled.div<Props>`
  text-align: center;
  font-size: 1.5rem;
  font-family: CircularStdBold;
  color: ${({ theme }) => theme.color.primary};

  @media (min-width: 768px) {
    font-size: calc(1.2rem + 0.8vw);
    line-height: 1.5;
    color: ${({ theme }) => theme.color.primary};
  }
`;

export default TextHolder;
