import styled from '@emotion/styled'

const LogoContainer = styled.div`
  display: flex;

  > img {
    max-width: 120px;
    height: 26px;
  }
`

export default LogoContainer
