import { useTheme } from "@emotion/react";

import Heading from "../styles/Heading";
import IconContainer from "../styles/IconContainer";
import Text from "../styles/Text";
import HeadingContainer from "../styles/HeadingContainer";

const ThridBox = (): JSX.Element => {
  const theme = useTheme() as any;

  const Links: Links[] = [
    {
      name: "About Us",
      url: "https://indiagold.co/about-us/",
    },
    {
      name: "Terms & Conditions",
      url: "https://indiagold.co/terms-and-conditions/",
    },
    {
      name: "Privacy Policy",
      url: "https://indiagold.co/privacy-policy/",
    },
    {
      name: "Join Us",
      url: "https://indiagold.co/join-us",
    },
  ];

  return (
    <>
      <HeadingContainer>
        <Heading theme={theme}>COMPANY</Heading>
      </HeadingContainer>

      {Links.map((link) => (
        <IconContainer theme={theme} key={link.name}>
          <Text href={link.url} target="_blank" color={theme.color.primary}>
            {link.name}
          </Text>
        </IconContainer>
      ))}
    </>
  );
};

interface Links {
  name: string;
  url: string;
}

export default ThridBox;
