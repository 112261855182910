import React, { useCallback, useEffect, useState } from "react";
import Container from "./styles/Container";
import TextContainer from "./styles/TextContainer";
import TitleContainer from "./styles/TitleContainer";
import InputBox from "./styles/InputBox";
import HeaderWrapper from "./styles/HeaderWrapper";
import YellowButton from "./styles/YellowButton";
import PaperComponent from "components/HeroSection/Paper";
import ModalComponent from "components/HeroSection/Modal";

import CrossWrapper from "./styles/CrossWrapper";

import CrossButton from "assets/Others/CrossButton";
import InputContainer from "./styles/InputContainer";

import ButtonWithTimer from "components/ButtonWithTimer";
import { CircularProgress } from "@material-ui/core";

const OTPModal = ({
  mobileNumber,
  onCrossClick,
  otp,
  setOtp,
  onClickOtpVerify,
  onClickResend,
  openOTPModal,
  disableButton,
}: OTPModalProps) => {
  const [timer, setTimer] = useState(30);

  const handleOtpChange = useCallback(
    (event: any) => setOtp(event.target.value),
    [setOtp]
  );

  const onClickVerifyOtpHandler = useCallback(() => {
    onClickOtpVerify();
  }, [onClickOtpVerify]);

  const onClickResendHandler = useCallback(() => {
    setTimer(30);
    onClickResend();
  }, [onClickResend]);

  useEffect(() => {
    const timerId = setInterval(() => setTimer((time) => time - 1), 1000);

    return () => clearInterval(timerId);
  }, [timer]);

  return (
    <>
      <ModalComponent open={openOTPModal}>
        <PaperComponent>
          <Container>
            <HeaderWrapper>
              <TitleContainer>Enter OTP</TitleContainer>
              <CrossWrapper onClick={onCrossClick}>
                <CrossButton />
              </CrossWrapper>
            </HeaderWrapper>

            <TextContainer>
              {`Please enter the OTP sent to ${mobileNumber} to submit your application`}
            </TextContainer>

            <InputContainer>
              <InputBox
                placeholder="OTP"
                type="number"
                value={otp}
                onChange={handleOtpChange}
              />
            </InputContainer>

            <YellowButton
              onClick={onClickVerifyOtpHandler}
              disabled={otp.length < 6 || disableButton}
            >
              VERIFY OTP
              {disableButton ? (
                <CircularProgress
                  size={14}
                  style={{ marginLeft: "10px", color: "#fff" }}
                  disableShrink
                />
              ) : null}
            </YellowButton>

            <ButtonWithTimer
              time={30}
              text={"RESEND OTP"}
              disabled={disableButton}
              onClick={onClickResendHandler}
            />
          </Container>
        </PaperComponent>
      </ModalComponent>
    </>
  );
};

interface OTPModalProps {
  mobileNumber: string;
  onCrossClick: () => void;
  otp: string;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  onClickOtpVerify: () => void;
  onClickResend: () => void;
  openOTPModal: boolean;
  disableButton: boolean;
}

export default OTPModal;
