import { AlertProps } from "@material-ui/lab/Alert";
import { Dispatch } from "redux";
import AppActions from "../types/actions";
import {
  ERROR_RESET,
  ERROR_START,
  SUCCESS_RESET,
  SUCCESS_START,
} from "../types/actions/status";

/// ********ERROR***********
export const ErrorResetAction = (): ((
  dispatch: Dispatch<AppActions>
) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: ERROR_RESET,
    });
  };
};

export const ErrorStartAction = (
  error: string,
  severity: AlertProps["severity"]
): ((dispatch: Dispatch<AppActions>) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: ERROR_START,
      payload: {
        errorMessage: error,
        severity,
      },
    });
  };
};

/// ********Success***********

export const SuccessResetAction = (): ((
  dispatch: Dispatch<AppActions>
) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SUCCESS_RESET,
    });
  };
};

export const SuccessStartAction = (
  message: string,
  severity: AlertProps["severity"]
): ((dispatch: Dispatch<AppActions>) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SUCCESS_START,
      payload: {
        successMessage: message,
        severity,
      },
    });
  };
};
