import styled from "@emotion/styled";

const ImageContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;

    & > img {
      height: 14.4rem;
      width: 16.8rem;
    }
  }

  @media (min-width: 768px) {
    > img {
      height: 100%;
      width: 100%;
    }
  }
`;

export default ImageContainer;
