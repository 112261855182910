import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}
const Heading = styled.div<Props>`
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 1.5rem;
    margin-top: 3rem;
    color: ${({ theme }) => theme.color.headerColor};
  }

  @media (min-width: 768px) {
    text-align: center;
    font-size: 0.8vw;
    line-height: 1;
    letter-spacing: 2px;
    margin-bottom: 1.5vw;
    margin-top: 2.8vw;

    color: ${({ theme }) => theme.color.headerColor};
  }
`;

export default Heading;
