const BlackEmailIcon = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6.405v7.19A2.4 2.4 0 0 0 4.4 16h11.2a2.4 2.4 0 0 0 2.4-2.405v-7.19A2.4 2.4 0 0 0 15.6 4H4.4A2.4 2.4 0 0 0 2 6.405zm8 6.356L3.776 6.966l1.088-1.17L10 10.58l5.136-4.785 1.088 1.17L10 12.76z"
        fill="url(#makyd8nxka)"
      />
      <defs>
        <linearGradient
          id="makyd8nxka"
          x1="13.514"
          y1="13.001"
          x2="9.348"
          y2="5.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#484540" />
          <stop offset="1" stopColor="#201913" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BlackEmailIcon;
