const NoFees = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#CE9F29"
              fillOpacity=".12"
              d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20z"
              transform="translate(-1043 -2001) translate(0 1874) translate(1044 128)"
            />
            <path
              stroke="#CE9F29"
              strokeDasharray="40 4"
              d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20"
              transform="translate(-1043 -2001) translate(0 1874) translate(1044 128)"
            />
            <g>
              <path
                d="M0 0H24V24H0z"
                transform="translate(-1043 -2001) translate(0 1874) translate(1044 128) translate(8 8)"
              />
              <path
                fill="#CE9F29"
                fillRule="nonzero"
                d="M8.142 14v-1.656h1.65v-.762H8.136v-1.05h1.86v-.786H7.308V14h.834zm5.202 0v-.78h-1.836v-.984h1.662v-.738h-1.662v-.972h1.836v-.78H10.68V14h2.664zm3.492 0v-.78H15v-.984h1.662v-.738H15v-.972h1.836v-.78h-2.664V14h2.664z"
                transform="translate(-1043 -2001) translate(0 1874) translate(1044 128) translate(8 8)"
              />
              <path
                fill="#CE9F29"
                fillRule="nonzero"
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zM4 12c0-1.846.634-3.542 1.688-4.897l11.209 11.209C15.499 19.406 13.775 20 12 20c-4.411 0-8-3.589-8-8zm14.312 4.897L7.103 5.688C8.501 4.594 10.225 4 12 4c4.411 0 8 3.589 8 8 0 1.775-.595 3.499-1.688 4.897z"
                transform="translate(-1043 -2001) translate(0 1874) translate(1044 128) translate(8 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NoFees;
