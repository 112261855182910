import styled from '@emotion/styled'

interface Props {
  isMobile: boolean
}

const GetItOnGooglePlay = styled.div<Props>`
  height: 35px;

  margin-left: ${({ isMobile }) =>
    isMobile ? '0.625rem' : '1.625rem'};
  align-items: center;

  cursor: pointer;
  display: flex;

  img {
    width: 100px;
    height: auto;

    margin-left: 5px;
  }
`

export default GetItOnGooglePlay
