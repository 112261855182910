import styled from "@emotion/styled";

const IconContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;

  @media (min-width: 768px) {
    min-width: 30%;
    align-items: center;
  }
`;

export default IconContainer;
