import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}

const SubHeader = styled.div<Props>`
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.color.headerColor};
  margin-bottom: 2rem;
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: 1vw;
    font-size: 0.8vw;
    line-height: 1;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.color.headerColor};
  }
`;

export default SubHeader;
