import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface Props {
  left?: boolean;
}

const Container = styled.div<Props>`
  padding-bottom: ${({ left }) => !left && "48px"};
  margin-top: ${({ left }) => left && "48px"};
  margin-bottom: ${({ left }) => left && "48px"};

  @media (min-width: 768px) {
    flex: ${({ left }) => (left ? 0.5 : 1)};

    padding: 0;
    margin: 0;

    ${({ left }) =>
      left &&
      css`
        display: flex;
        justify-content: center;
        align-items: center;
      `}
  }
`;

export default Container;
