import styled from "@emotion/styled";
import React from "react";

interface Props {
  color?: React.CSSProperties["color"];
  size?: React.CSSProperties["fontSize"];
  DesktopFontSize?: React.CSSProperties["fontSize"];
  marginBottom?: React.CSSProperties["marginBottom"];
  marginTop?: React.CSSProperties["marginTop"];
  mobileMarginTop?: React.CSSProperties["marginTop"];
  mobileMarginBottom?: React.CSSProperties["marginBottom"];
  DesktopLineHeight?: React.CSSProperties["lineHeight"];
  FontFamily?: React.CSSProperties["fontFamily"];
  isFontBold?: boolean;
  letterSpacing?: React.CSSProperties["letterSpacing"];
  textAlign?: React.CSSProperties["textAlign"];
  TabFontSize?: React.CSSProperties["fontSize"];
  mobileTextSize?: React.CSSProperties["fontSize"];
  lineHeight?: React.CSSProperties["lineHeight"];
  DesktopTextAlign?: React.CSSProperties["textAlign"];
  mobilePadding?: React.CSSProperties["padding"];
  width?: React.CSSProperties["width"];
  margin?: React.CSSProperties["margin"];
  isHidden?: boolean;
}

const Title = styled.div<Props>`
  width: ${({ width = "100%" }) => width};
  font-weight: ${({ isFontBold }) => {
    return isFontBold ? "normal" : "bold ";
  }};
  text-align: ${({ textAlign }) => {
    return textAlign || "inherit";
  }};

  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop = "0" }) => marginTop};
  margin-bottom: ${({ marginBottom = "0" }) => marginBottom};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  display: ${({ isHidden }) => (isHidden ? "none" : "block")};

  @media (min-width: 768px) {
    font-size: ${({ DesktopFontSize }) => DesktopFontSize};
    line-height: ${({ DesktopLineHeight }) => DesktopLineHeight};
    text-align: ${({ DesktopTextAlign }) => DesktopTextAlign};
  }

  @media (max-width: 1200px) and (min-width: 770px) {
    font-size: ${({ TabFontSize }) => TabFontSize};
  }

  @media (max-width: 768px) {
    margin-top: ${({ mobileMarginTop }) => mobileMarginTop};
    margin-bottom: ${({ mobileMarginBottom }) => mobileMarginBottom};
    font-size: ${({ mobileTextSize }) => mobileTextSize};
    padding: ${({ mobilePadding }) => mobilePadding};
  }
`;

export default Title;
