const BlackTelephoneIcon = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#saf7iuumwa)">
        <path
          d="M14.924 12.44c-.906-.775-1.825-1.244-2.72-.47l-.535.467c-.39.34-1.118 1.926-3.929-1.307-2.81-3.23-1.138-3.733-.746-4.07l.537-.468c.89-.775.554-1.752-.088-2.757l-.387-.609c-.645-1.002-1.348-1.66-2.24-.886l-.483.421c-.394.287-1.497 1.222-1.765 2.997-.322 2.13.694 4.568 3.02 7.244 2.325 2.676 4.6 4.02 6.755 3.998 1.792-.02 2.873-.981 3.212-1.33l.484-.422c.89-.774.337-1.562-.57-2.339l-.545-.469z"
          fill="#221B15"
        />
      </g>
      <defs>
        <clipPath id="saf7iuumwa">
          <path fill="#fff" transform="translate(2 2)" d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlackTelephoneIcon;
