import styled from '@emotion/styled'
import { ThemeProps } from 'Theme/theme'

interface Props {
  theme: ThemeProps
}

const Heading = styled.h4<Props>`
  font-size: 0.75rem;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;

  line-height: 2;
  letter-spacing: 0.6px;

  color: ${({ theme }) => theme.color.headerColor};
`

export default Heading
