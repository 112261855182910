import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}
const SubmitButton = styled.button<Props>`
  font-family: CircularStdMedium;
  letter-spacing: 0.5px;
  background-color: ${({ theme }) => theme.color.primary};
  box-shadow: 0 2px 8px 0 rgba(206, 159, 41, 0.2);
  border-radius: 2px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 3.2rem;
    border: none;
    font-size: 0.875rem;
    letter-spacing: 0.5px;
  }

  @media (min-width: 768px) {
    min-height: 16%;
    font-size: 0.8vw;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export default SubmitButton;
