import styled from "@emotion/styled";

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    > div {
      height: 185px !important;
    }
  }

  @media (min-width: 768px) {
    height: 468px !important;
  }
`;

export default VideoContainer;
