import AppLayout from "container/AppLayout";
import REACTGA from "react-ga";

const LandingPage = () => {
  REACTGA.pageview("/", undefined, "Home Page");

  return <AppLayout />;
};

export default LandingPage;
