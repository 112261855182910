import styled from "@emotion/styled";

const ReasonsContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }
`;

export default ReasonsContainer;
