import LandingPage from "./pages/LandingPage";
import Theme from "./Theme";
import { Provider } from "react-redux";
import store from "store";
import StatusWrapper from "container/StatusWrapper";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";

const { REACT_APP_REACTGA, REACT_APP_GTM_ID } = process.env;

if (REACT_APP_REACTGA) {
  ReactGA.initialize(REACT_APP_REACTGA, {
    debug: false,
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });
}

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID as string,
  events: {
    lead: "Lead",
  },
};

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL as string);

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <Provider store={store()}>
      <Theme>
        <StatusWrapper>
          <Router>
            <LandingPage />
          </Router>
        </StatusWrapper>
      </Theme>
    </Provider>
  );
}

export default App;
