import styled from "@emotion/styled";

const VideoWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // min-height: 80vh;

  @media (max-width: 768px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @media (min-width: 768px) {
    min-height: 70vh;
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
`;

export default VideoWrapper;
