import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: column;
    padding: 6vw 10%;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
`;

export default Container;
