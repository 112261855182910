import React from "react";

const CrossButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g fill="none" fillRule="evenodd" opacity=".5">
      <g fillRule="nonzero">
        <g>
          <g>
            <g fill="#121212">
              <path
                d="M18.121 16l5.938-5.938c.587-.585.587-1.536.002-2.123-.586-.586-1.537-.587-2.123-.002L16 13.875l-5.938-5.938c-.586-.586-1.538-.586-2.124 0-.587.587-.587 1.539 0 2.125L13.875 16l-5.938 5.938c-.586.586-.586 1.538 0 2.125.587.586 1.539.586 2.125 0L16 18.125l5.938 5.938c.586.586 1.538.586 2.125 0 .586-.587.586-1.539 0-2.125L18.12 16z"
                transform="translate(-864 -317) translate(520 293) translate(344 24)"
              />
            </g>
            <path
              d="M0 0H32V32H0z"
              transform="translate(-864 -317) translate(520 293) translate(344 24)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CrossButton;
