import styled from "@emotion/styled";

const WhyChooseSection = styled.div`
  width: 96.5vw;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    width: 54%;
    height: 100%;
    margin-left: 4vw;
    padding: 3vw 0;
  }
`;

export default WhyChooseSection;
