import React, { useMemo } from "react";
import { InfoContainer, Text } from "./styles";
import { ANNUAL_APR, REPRESSENTATION_EXAMPLE } from "lib/constant";

const InfoContainerComponents = ({
  removePaddingTop,
  isMarginZero,
}: InfoContainerProps): JSX.Element => {
  const textList = useMemo(
    () => [
      `*${ANNUAL_APR}% Annual percentage rate ( APR ). Only in certain Geographies and with certain lenders. T&C Apply.`,
      "^ Indicative time measured for a loan of Rs 50,000 carried under controlled conditions.",
      REPRESSENTATION_EXAMPLE,
      "Minimum period of repayment is 61 days and Maximum period of repayment is 12 months. Effective monthly interest rate post rebate in Regular Rebate Max Value Scheme with Loan Tenure of 6 months",
      "Contact Information (Registered Office): 5th Floor, Holly Hocks Pvt Ltd, Plot #64, Sector 44, Gurgaon, Haryana, 122002. Phone Number: 080-68495878, 080-45305174",
      "Associated Fees and Charges : There are no associated fees and charges in indiagold schemes. Foreclosure charges could be levied on some of the loan schemes",
      "We don't offer personal loans which require repayment in full in 60 days or less from the date the loan is issued. Subject to the necessary KYC and verification. T&C apply",
      `Maximum Annual Percentage Rate (APR) is ${ANNUAL_APR}%. For any queries, you can email us at support@indiagold.co. For any escalations, you can write to our Grievance Officer at compliance@indiagold.co.`,
      "DISCLAIMER: Flat White Capital Pvt. Ltd. (indiagold) partners with regulated financial institutions such as banks & NBFCs & is not a bank or NBFC itself nor does it hold or claim to have a banking or NBFC license.",
    ],
    []
  );

  return (
    <InfoContainer
      isMarginZero={Boolean(isMarginZero)}
      isPaddingZero={Boolean(removePaddingTop)}
    >
      {textList.map((text, index) => (
        <React.Fragment key={index}>
          <Text dangerouslySetInnerHTML={{ __html: text }} key={text} />
          <br />
        </React.Fragment>
      ))}
    </InfoContainer>
  );
};

interface InfoContainerProps {
  removePaddingTop?: boolean;
  isMarginZero?: boolean;
}

export default InfoContainerComponents;
