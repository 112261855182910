import VideoContainer from "./styles/VideoContainer";
import VideoWrapper from "./styles/VideoWrapper";
import Video from "./styles/Video";
import QuoteSection from "components/QuoteSection";
import MiniDivider from "components/StepsSection/styles/MiniDivider";

const VideoSection = () => {
  return (
    <VideoWrapper>
      <QuoteSection>
        indiagold gives you the best gold loan in the market
      </QuoteSection>
      <MiniDivider />

      <VideoContainer>
        <Video
          src="https://www.youtube-nocookie.com/embed/4g7-pctwt74"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        ></Video>
      </VideoContainer>
    </VideoWrapper>
  );
};

export default VideoSection;
