import { useCallback, useEffect, useState } from "react";
import Logo from "assets/LandingPage/Logo";
import ApplyNowButton from "./styles/ApplyNowButton";
import HeaderWrapper from "./styles/HeaderWrapper";
import LogoWrapper from "./styles/LogoWrapper";
import onGaHandler from "lib/onGaHandler";
import { useTheme } from "@emotion/react";

const Header = () => {
  const [buttonOpacity, setButtonOpacity] = useState(window.scrollY);

  const buttonOpacityHandler = useCallback(() => {
    if (window.innerWidth >= 768) {
      if (window.scrollY >= 659) {
        setButtonOpacity(1);
      } else {
        setButtonOpacity(0);
      }
    } else {
      if (window.scrollY >= 1151) {
        setButtonOpacity(1);
      } else {
        setButtonOpacity(0);
      }
    }
  }, []);

  const handleTopApplyNowClick = useCallback(() => {
    onGaHandler("apply now sticky");
    if (window.innerWidth > 768) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 752,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", buttonOpacityHandler);

    return () => {
      window.removeEventListener("scroll", buttonOpacityHandler);
    };
  }, [buttonOpacityHandler]);

  const theme = useTheme() as any;

  return (
    <HeaderWrapper theme={theme}>
      <LogoWrapper
        onClick={() => (window.location.href = "https://indiagold.co/")}
      >
        <Logo />
      </LogoWrapper>

      <ApplyNowButton
        style={{ opacity: buttonOpacity }}
        onClick={handleTopApplyNowClick}
      >
        {"APPLY NOW"}
      </ApplyNowButton>
    </HeaderWrapper>
  );
};

export default Header;
