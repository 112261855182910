import styled from "@emotion/styled";

const Video = styled.iframe`
  width: 100%;
  height: 250px;

  @media (min-width: 768px) {
    width: 834px;
    height: 468px;
  }
`;

export default Video;
