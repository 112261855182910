import styled from "@emotion/styled";

const MobileContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export default MobileContainer;
