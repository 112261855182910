import { useTheme } from "@emotion/react";
import InstagramIcon from "assets/SocialMediaIcons/Instagram";
import LinkedinIcon from "assets/SocialMediaIcons/Linkedin";
import FacebookIcon from "assets/SocialMediaIcons/Facebook";

import HeadingContainer from "../styles/HeadingContainer";
import Heading from "../styles/Heading";
import IconContainer from "../styles/IconContainer";
import SocialMediaIcon from "../styles/SocialMediaIcon";
import BlackTelephone from "./icons/BlackTelephoneIcon";
import BlackEmail from "./icons/BlackEmailIcon";
import { ContactContainer } from "./styles";
import Complaince from "../FirstBox/Complaince";
import useMobile from "hooks/useMobile";

const ForthBox = (): JSX.Element => {
  const theme = useTheme() as any;
  const isMobile = useMobile();

  return (
    <>
      <HeadingContainer>
        <Heading theme={theme}>CONTACT US</Heading>
      </HeadingContainer>
      <ContactContainer>
        <BlackTelephone />
        <>080-68495878</>
      </ContactContainer>
      <ContactContainer>
        <BlackEmail />
        <>support@indiagold.co</>
      </ContactContainer>

      <IconContainer theme={theme}>
        <SocialMediaIcon
          href="https://www.instagram.com/indiagoldapp/"
          target="_blank"
        >
          <InstagramIcon />
        </SocialMediaIcon>

        <SocialMediaIcon
          href="https://www.linkedin.com/company/flat-white-capital-pvt-ltd/"
          target="_blank"
        >
          <LinkedinIcon />
        </SocialMediaIcon>

        <SocialMediaIcon
          href="https://www.facebook.com/indiagoldbuy/"
          target="_blank"
        >
          <FacebookIcon />
        </SocialMediaIcon>
      </IconContainer>
      {isMobile && <Complaince />}
    </>
  );
};

export default ForthBox;
