import React from "react";

const Facebook = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g fill="#3F5795">
            <path
              d="M17 0H1C.448 0 0 .448 0 1v16c0 .552.448 1 1 1h8.615v-6.96H7.277V8.315h2.338v-2c0-2.325 1.42-3.592 3.5-3.592.699-.002 1.399.034 2.095.107v2.42h-1.435c-1.128 0-1.348.538-1.348 1.325V8.31h2.697l-.35 2.725h-2.348V18H17c.552 0 1-.448 1-1V1c0-.552-.448-1-1-1z"
              transform="translate(-16 -1107) translate(16 1107) translate(3 3)"
            />
          </g>
          <path
            d="M0 0H24V24H0z"
            transform="translate(-16 -1107) translate(16 1107)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Facebook;
