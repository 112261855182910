import { Status } from "types/reducers/status";
import {
  ErrorActions,
  ERROR_START,
  ERROR_RESET,
  SUCCESS_START,
  SUCCESS_RESET,
} from "types/actions/status";

export const StatusDefaultState: Status = {
  error: false,
  success: false,
  message: "",
  severity: "info",
};

const StatusReducer = (
  state = StatusDefaultState,
  action: ErrorActions
): Status => {
  switch (action.type) {
    case ERROR_RESET: {
      return {
        ...state,
        error: false,
        message: "",
      };
    }
    case ERROR_START: {
      return {
        ...state,
        error: true,
        message: action.payload.errorMessage.toString(),
        severity: action.payload.severity,
      };
    }
    case SUCCESS_START: {
      return {
        ...state,
        success: true,
        message: action.payload.successMessage.toString(),
        severity: action.payload.severity,
      };
    }
    case SUCCESS_RESET: {
      return {
        ...state,
        success: false,
        message: "",
      };
    }
    default:
      return state;
  }
};

export default StatusReducer;
