import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface ExtraInfoContainerProps {
  theme?: ThemeProps;
}

const ExtraInfoContainer = styled.div<ExtraInfoContainerProps>`
  color: ${({ theme }) => theme.color.headerColor};
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

export default ExtraInfoContainer;
