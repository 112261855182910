import React from "react";
import Container from "./styles/Container";
import Button from "./styles/Button";
import useTimer from "hooks/useTimer";
import { useTheme } from "@emotion/react";
import { CircularProgress } from "@material-ui/core";

const ButtonWithTimer = ({
  time,
  text,
  disabled,
  onClick,
}: ButtonProps): JSX.Element => {
  const [timer, setTimer] = useTimer(time);

  const onClickButton = () => {
    setTimer(time);
    onClick();
  };
  const theme = useTheme() as any;

  return (
    <Container onClick={onClickButton}>
      <Button theme={theme} disabled={timer > 0 || disabled}>
        {timer > 0 ? `RESEND OTP IN 00:${timer}` : text}
        {disabled ? (
          <CircularProgress
            style={{ marginLeft: "10px", color: "#fff" }}
            disableShrink
          />
        ) : null}
      </Button>
    </Container>
  );
};

interface ButtonProps {
  time: number;
  text: string;
  disabled: boolean;
  onClick: () => void;
}

export default ButtonWithTimer;
