import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}

const FormSection = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5vw;
  margin-bottom: 2vw;

  @media (max-width: 768px) {
    border-radius: 4px;
  }

  @media (min-width: 768px) {
    width: 53vw;
    height: fit-content;
    display: flex;
    flex-direction: row;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: ${({ theme }) => theme.background.white};
  }
`;

export default FormSection;
