import { Box } from "@material-ui/core";
import Title from "components/Text/Title";
import ComplianceLogo from "../icons/ISO.png";
import GreatPlaceToWorkImage from "../icons/great-place-to-work.webp";
import { Image } from "./styles";

const Complaince = () => {
  return (
    <Box marginTop="2rem">
      <Title
        DesktopFontSize="0.875rem"
        color="rgb(203, 203, 203)"
        marginBottom="0.5rem"
        isFontBold={true}
        lineHeight={2}
      >
        COMPLIANCE CERTIFICATE
      </Title>
      <Box
        height="5rem"
        width="6.75rem"
        marginTop="1rem"
        display="flex"
        style={{ gap: "1rem" }}
      >
        <Image src={ComplianceLogo} alt="comliance" />
        <Image src={GreatPlaceToWorkImage} alt="great-place-to-work" />
      </Box>
    </Box>
  );
};

export default Complaince;
