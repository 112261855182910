import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme?: ThemeProps;
  textAlign?: string;
}

const QuoteSection = styled.div<Props>`
  color: ${({ theme }) => theme.color.bodyBlack};
  font-family: CircularStdBold;
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: 768px) {
    font-size: 1.875rem;
    text-align: center;
    line-height: 1.57;
    margin: 1.5rem 1rem;
  }

  @media (min-width: 768px) {
    font-size: 2.4vw;
    line-height: 1.6;
    margin-top: 0;
    margin-left: 4vw;
    margin-bottom: 2vw;
    padding-right: 8vw;
  }
`;

export default QuoteSection;
