const GreenCheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g>
            <g fill="#00B3A6">
              <path
                d="M27 .333C12.296.333.333 12.296.333 27 .333 41.704 12.296 53.667 27 53.667c14.704 0 26.667-11.963 26.667-26.667C53.667 12.296 41.704.333 27 .333zm0 48c-11.763 0-21.333-9.57-21.333-21.333 0-11.763 9.57-21.333 21.333-21.333 11.763 0 21.333 9.57 21.333 21.333 0 11.763-9.57 21.333-21.333 21.333z"
                transform="translate(-688 -363) translate(520 331) translate(168 32) translate(5 5)"
              />
              <path
                d="M21.664 31.232L15.533 25.112 11.768 28.888 21.669 38.768 39.552 20.885 35.781 17.115z"
                transform="translate(-688 -363) translate(520 331) translate(168 32) translate(5 5)"
              />
            </g>
            <path
              d="M0 0H64V64H0z"
              transform="translate(-688 -363) translate(520 331) translate(168 32)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GreenCheckIcon;
