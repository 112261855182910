const LoanAtHomeService = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#CE9F29"
              fillOpacity=".12"
              d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20z"
              transform="translate(-1043 -1937) translate(0 1874) translate(1044 64)"
            />
            <path
              stroke="#CE9F29"
              strokeDasharray="40 4"
              d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20"
              transform="translate(-1043 -1937) translate(0 1874) translate(1044 64)"
            />
            <g fillRule="nonzero">
              <g fill="#CE9F29">
                <path
                  d="M5.385 19.788c.242.19.559.257.857.182l8-2c.446-.11.758-.51.758-.97V3c0-.459-.313-.859-.758-.97l-8-2c-.299-.073-.614-.006-.856.184C5.143.403 5 .693 5 1v1H1c-.552 0-1 .448-1 1v14c0 .552.448 1 1 1h4v1c0 .308.142.599.385.788zM7 2.281l6 1.5v12.438l-6 1.5V2.281zM2 16V4h3v12H2z"
                  transform="translate(-1043 -1937) translate(0 1874) translate(1044 64) translate(8 8) translate(5 2)"
                />
                <path
                  d="M9.242 11.159c.446-.112.758-.512.758-.971v-.377c0-.552-.448-1-1-1-.553 0-1 .449-1 1.001v.377c0 .308.142.598.384.788.243.19.56.256.858.182z"
                  transform="translate(-1043 -1937) translate(0 1874) translate(1044 64) translate(8 8) translate(5 2)"
                />
              </g>
              <path
                d="M0 0H24V24H0z"
                transform="translate(-1043 -1937) translate(0 1874) translate(1044 64) translate(8 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LoanAtHomeService;
