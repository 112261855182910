import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}
const Button = styled.button<Props>`
  height: 3.125rem;
  width: 100%;

  border: none;
  border-radius: 2px;

  font-size: 0.875rem;
  font-family: CircularStdBold;

  line-height: 1.14;
  letter-spacing: 0.5px;

  border-shadow: 0 2px 4px 0 ${({ theme }) => theme.background.sectionSeparator};

  border: solid 1px ${({ theme }) => theme.background.itemBounds};

  background-color: ${({ theme }) => theme.background.white};
`;

export default Button;
