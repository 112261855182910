import styled from "@emotion/styled";

const ImageHolder = styled.div`
  background: white;
  padding: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    background: white;
    padding: 1rem;
    margin-left: 1rem;
  }
`;

export default ImageHolder;
