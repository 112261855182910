import styled from "@emotion/styled";

const SubTextHolder = styled.div`
  font-family: CircularStdMedium;
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    text-align: center;
    padding: 0 3rem;
  }

  @media (min-width: 768px) {
    font-size: 1.2vw;
    text-align: left;
    padding: 0;
    line-height: 1.56;
  }
`;

export default SubTextHolder;
