import { ErrorResponse } from "types/api";

export const ErrorResponseHandler = (error: any): ErrorResponse => {
  if (error.response) {
    // client received an error response (5xx, 4xx)
    const statusCode = error.response.status;

    if (statusCode >= 400 && statusCode < 500) {
      const { data } = error.response;

      if (statusCode === 404) {
        return {
          statusCode,
          payload: null,
          error: "Not Found",
          message: null,
        };
      }

      return {
        statusCode,
        payload: null,
        error: data.error.message,
        message: null,
      };
    }

    return {
      statusCode,
      payload: null,
      error: "Something went wrong",
      message: null,
    };
  }
  if (error.request) {
    // client never received a response, or request never left
    console.log("else if");

    return {
      statusCode: 500,
      payload: null,
      error: "Something went wrong",
      message: null,
    };
  }
  // anything else
  console.log("else");
  return {
    statusCode: 500,
    payload: null,
    error: error.toString(),
    message: null,
  };
};
