const SuccessIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              d="M0 0L32 0 32 32 0 32z"
              transform="translate(-64 -416) translate(64 416)"
            />
            <g fill="#CE9F29">
              <path
                d="M13.333 0C5.981 0 0 5.981 0 13.333s5.981 13.334 13.333 13.334 13.334-5.982 13.334-13.334C26.667 5.981 20.685 0 13.333 0zm0 2c6.271 0 11.334 5.062 11.334 11.333s-5.063 11.334-11.334 11.334C7.063 24.667 2 19.604 2 13.333 2 7.063 7.062 2 13.333 2zm4.98 7.324c-.259.008-.505.116-.687.302l-5.96 5.96L9.04 12.96c-.25-.262-.623-.367-.973-.275-.35.09-.624.365-.716.715-.091.35.014.723.275.974l3.334 3.333c.39.39 1.023.39 1.414 0l6.666-6.667c.296-.287.384-.726.224-1.106-.161-.38-.538-.622-.95-.61z"
                transform="translate(-64 -416) translate(64 416) translate(2.667 2.667)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SuccessIcon;
