import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  displays: boolean;
  theme: ThemeProps;
}

const InputContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ displays }) =>
    displays ? "calc(1.5rem - 0.75rem - 3px)" : "1.5rem"};

  @media (min-width: 768px) {
    & > ::placeholder {
      font-size: 1.125vw;
      color: ${({ theme }) => theme.color.headerColor};
    }

    margin-bottom: ${({ displays }) =>
      displays ? "calc(1vw - 0.75vw - 3px)" : "1vw"};
  }

  & > input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & > input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export default InputContainer;
