import styled from "@emotion/styled";

const InputContainer = styled.div`
  & > input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & > input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export default InputContainer;
