import styled from "@emotion/styled";

const IconWrapper = styled.div`
  width: 80%;
  margin: 0 auto;

  > div:first-of-type {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    > div:first-of-type {
      margin-top: 1.5rem;
    }

    width: unset;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default IconWrapper;
