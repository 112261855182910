import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}

const Button = styled.button<Props>`
  width: 55%;
  height: 2.5rem;
  border: none;
  background: ${({ theme }) => theme.color.primary};
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(206, 159, 41, 0.2);

  cursor: pointer;
`;

export default Button;
