import REACTGA from "react-ga";

const onGAHandler = (label: string) => {
  REACTGA.event({
    action: "click",
    category: "goldloanleadform-partner",
    label: label,
  });
};

export default onGAHandler;
