import Footer from "components/Footer";
import Header from "components/Header";
import HeroSection from "components/HeroSection";
import PartnerSection from "components/PartnerSection";
import StepsSection from "components/StepsSection";
import RatingSection from "components/RatingSection";
import AppLayoutWrapper from "./styles/AppLayoutWrapper";
import VideoSection from "components/VideoSection";
import PromiseSection from "components/PromiseSection";
import InfoContainerWrapper from "./styles/InfoContainerWrapper";
import InfoContainerComponents from "components/InfoContainer";

const AppLayout = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <PartnerSection />
      <StepsSection />
      <PromiseSection />

      <AppLayoutWrapper>
        <VideoSection />
      </AppLayoutWrapper>

      <RatingSection />
      <InfoContainerWrapper>
        <InfoContainerComponents isMarginZero />
      </InfoContainerWrapper>

      <Footer />
    </>
  );
};

export default AppLayout;
