import { useEffect, useState } from "react";

const useTimer = (
  time: number
): [number, React.Dispatch<React.SetStateAction<number>>] => {
  const [timer, setTimer] = useState(time);

  useEffect(() => {
    let timerId = setTimeout(() => setTimer((t) => t - 1), 1000);

    return () => clearInterval(timerId);
  }, [timer]);

  return [timer, setTimer];
};

export default useTimer;
