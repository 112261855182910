const createIFrameWithPixel = (url: string) => {
  const iFrame = document.createElement("iframe");
  iFrame.setAttribute("src", url);
  iFrame.setAttribute("scrolling", "no");
  iFrame.setAttribute("frameborder", "0");
  iFrame.height = "0";
  iFrame.width = "0";

  return iFrame;
};

export default createIFrameWithPixel;
