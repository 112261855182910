import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}
const Container = styled.div<Props>`
  padding: 6vw 0;
  border: solid 1px ${({ theme }) => theme.background.itemBounds};
  background: ${({ theme }) => theme.background.extraLightGrey};

  @media (max-width: 768px) {
    padding-top: 4vw;
    padding-bottom: 4vw;
  }
`;

export default Container;
