import Axios from "axios";
import { ErrorResponseHandler } from "../index";
import { SuccessResponse, ErrorResponse } from "types/api";

const verifyOtp = async ({
  mobile,
  otp,
  otpId,
}: any): Promise<SuccessResponse<any> | ErrorResponse> => {
  try {
    const response = await Axios.post(
      `${process.env.REACT_APP_BASE_URL}/user/verify-otp`,
      {
        mobile,
        otp,
        otpId,
      }
    );

    const result = response.data;

    if (response.status !== 200) {
      throw new Error(result.error.message);
    }

    return {
      statusCode: 200,
      message: "Success",
      payload: result.result,
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default verifyOtp;
