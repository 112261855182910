import styled from "@emotion/styled";

export const Text = styled.div`
  text-align: start;
  width: 100%;
  font-size: 0.75rem;
  line-height: 1.33;
  color: #ffffff;

  > a {
    color: #ffffff;
  }
`;

export const InfoContainer = styled.div<{
  isPaddingZero: boolean;
  isMarginZero: boolean;
}>`
  background-color: #262626;
  border: solid 1px #f3efec;

  color: white;

  padding: ${({ isPaddingZero }) => (isPaddingZero ? "0" : "1rem")};
  margin-top: ${({ isMarginZero }) => (isMarginZero ? "0" : "4rem")};
  margin-bottom: 4rem;
  border-radius: 10px;

  @media (min-width: 768px) {
    width: 100%;
    padding: 2.5rem 1.5rem 1.5rem 2.5rem;
  }
`;
