import React, { useCallback } from "react";

import { useTheme } from "@emotion/react";

import GetItInOnGooglePlay from "assets/Others/googlePlay.png";
import GetItInOnIOS from "assets/Others/download-ios.png";

import GetItOnGooglePlayContainer from "../styles/GetItOnGooglePlayContainer";
import LogoContainer from "../styles/LogoContainer";

import LocationIcon from "../Icons/location.png";
import LocationIconContainer from "../styles/LocationIconContainer";
import IconContainer from "../styles/IconContainer";
import Text from "../styles/Text";
import useMobile from "hooks/useMobile";
import ExtraInfoContainer from "../styles/ExtraInfoContainer";

import { extraInfo, address } from "./helper";
import Complaince from "./Complaince";

const FirstBox = (): JSX.Element => {
  const theme = useTheme() as any;
  const isMobile = useMobile();

  const onGooglePlayClickHandler = useCallback(() => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=co.indiagold.gold.buy.loan";
  }, []);
  const onIosHandler = useCallback(() => {
    window.location.href =
      "https://apps.apple.com/in/app/indiagold-gold-loan-at-home/id1549055305";
  }, []);

  const onKeyDownHandler = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter" && event.currentTarget.id === "android") {
        onGooglePlayClickHandler();
      }

      if (event.key === "Enter" && event.currentTarget.id === "ios") {
        onIosHandler();
      }
    },
    [onGooglePlayClickHandler, onIosHandler]
  );

  return (
    <>
      <LogoContainer>
        <img
          src="https://assets.bestgoldindia.com/production/indiagold_transparent_logo_website.png"
          alt="logo"
        />

        <GetItOnGooglePlayContainer isMobile={isMobile}>
          <div
            tabIndex={0}
            role="button"
            onKeyDown={onKeyDownHandler}
            onClick={onGooglePlayClickHandler}
            id="android"
          >
            <img src={GetItInOnGooglePlay} alt="google_play" />
          </div>

          <div
            tabIndex={0}
            role="button"
            onKeyDown={onKeyDownHandler}
            onClick={onIosHandler}
            id="ios"
          >
            <img src={GetItInOnIOS} alt="app_store" />
          </div>
        </GetItOnGooglePlayContainer>
      </LogoContainer>

      {address.map((addres) => (
        <IconContainer theme={theme} key={addres.name}>
          <img src={LocationIcon} alt="office_location" />
          <LocationIconContainer>
            <Text>{addres.name}</Text>
          </LocationIconContainer>
        </IconContainer>
      ))}

      {extraInfo.map((info, idx) => (
        <ExtraInfoContainer key={`${info}-${idx}`}>{info}</ExtraInfoContainer>
      ))}

      {!isMobile && <Complaince />}
    </>
  );
};
export default FirstBox;
