import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}

const StepsSection = styled.div<Props>`
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
  background: ${({ theme }) => theme.background.extraLightGrey};
`;

export default StepsSection;
