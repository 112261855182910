import Axios from "axios";
import { ErrorResponseHandler } from "../index";

import { SuccessResponse, ErrorResponse } from "types/api";

const sendOtp = async ({
  mobile,
  otpId,
  otp,
}: any): Promise<SuccessResponse<any> | ErrorResponse> => {
  try {
    const response = await Axios.patch(
      `${process.env.REACT_APP_LEAD_SERVICE_BASE_URL}/lead/landing-page/verified?leadType=GOLD_LOAN&mobile=${mobile}&otpId=${otpId}&otp=${otp}`
    );

    const result = response.data;

    if (response.status !== 200) {
      throw new Error(result.error.message);
    }

    return {
      statusCode: 200,
      message: "Success",
      payload: result.result,
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default sendOtp;
