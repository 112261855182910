import { useTheme } from "@emotion/react";
import React from "react";
import Title from "./Title";

const PrimaryTitle = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const theme = useTheme() as any;

  return <Title theme={theme}>{children}</Title>;
};

export default PrimaryTitle;
