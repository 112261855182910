import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}
const Section = styled.section<Props>`
  display: flex;
  background-color: ${({ theme }) => theme.background.goldLight};
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    min-height: 25vh;
    align-items: center;
  }
`;

export default Section;
