import styled from "@emotion/styled";

import { ThemeProps } from "Theme/theme";

interface Props {
  theme?: ThemeProps;
}

const InputBox = styled.input<Props>`
  width: 100%;
  height: 3.125rem;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;

  border: solid 1px ${({ theme }) => theme.background.itemBounds};
  font-size: 1.125rem;

  background: ${({ theme }) => theme.background.white};

  & > ::placeholder {
    font-size: 1.125rem;
    line-height: 1;
    color: ${({ theme }) => theme.color.headerColor};
  }
`;

export default InputBox;
