import styled from "@emotion/styled";

const FormHolder = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    justify-content: center;
    width: calc(100% - 2rem);
    background: white;
    padding: 2rem 1.375rem;
    line-height: 1.5;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 768px) {
    justify-content: space-between;
    background: #fcfcfc;
    height: 100%;
    width: 45%;
    padding: 2vw 2vw;
  }
`;

export default FormHolder;
