import React from "react";
import Container from "./styles/Container";
import RatingsContainer from "./styles/RatingsContainer";
import ImageHolder from "./styles/ImageHolder";

import justDialLogo from "./Icons/justDial.png";
import googleLogo from "./Icons/google.png";
import PrimaryTitle from "components/PrimaryTitle";
import { useTheme } from "@emotion/react";

const RatingSection = () => {
  const images = [justDialLogo, googleLogo];
  const theme = useTheme() as any;

  return (
    <Container theme={theme}>
      <PrimaryTitle>Trusted by over 1 lakh customers</PrimaryTitle>

      <RatingsContainer>
        {images.map((img, i) => (
          <ImageHolder key={i}>
            {<img src={img} alt={"logo-" + i} />}
          </ImageHolder>
        ))}
      </RatingsContainer>
    </Container>
  );
};

export default RatingSection;
