import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}
const IconText = styled.p<Props>`
  font-size: 1.125rem;

  font-family: CircularStdMedium;

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  color: ${({ theme }) => theme.color.bodyBlack};

  align-items: center;
  display: flex;

  margin-left: 0.75rem;
`;

export default IconText;
