import React from "react";

const LeftIcon = ({ isActive }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <defs>
      <filter id="f3i1g0gb9a" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.070588 0 0 0 0 0.070588 0 0 0 0 0.070588 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" opacity={isActive ? "" : ".5"}>
      <g>
        <g transform="translate(-191 -2465) translate(192 2466)">
          <circle cx="24" cy="24" r="24" fill="#FFF" stroke="#E2E2E2" />
          <g>
            <path d="M0 0L24 0 24 24 0 24z" transform="translate(12 12)" />
            <g filter="url(#f3i1g0gb9a)" transform="translate(12 12)">
              <g>
                <path
                  fill="#121212"
                  d="M.992.992c-.305 0-.58.185-.695.468-.114.283-.046.608.173.82l3.47 3.47L.47 9.22c-.196.188-.275.467-.207.73.069.263.274.468.537.537.263.068.542-.01.73-.207l4-4c.293-.293.293-.767 0-1.06l-4-4C1.39 1.074 1.195.992.992.992z"
                  transform="translate(9 7)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

interface Props {
  isActive: boolean;
}

export default LeftIcon;
