import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  displays: boolean;
  theme: ThemeProps;
}

const InputBox = styled.input<Props>`
  border: solid 1px
    ${({ theme, displays }) =>
      !displays ? theme.background.itemBounds : theme.color.errorRed};
  outline: none;
  border-radius: 4px;

  @media (max-width: 768px) {
    height: 3.2rem;
    font-size: 1.125rem;
    padding-left: 1rem;
  }

  @media (min-width: 768px) {
    height: 3vw;
    font-size: 1.125vw;
    line-height: 1;
    padding-left: 1vw;
    color: ${({ theme }) => theme.color.bodyBlack};
  }
`;

export default InputBox;
