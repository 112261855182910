import PrimaryTitle from "components/PrimaryTitle";
import Section from "./styles/Section";
import Container from "./styles/Container";

import HighestGramPerRateIcon from "assets/LandingPage/PromiseSection/HighestGramPerRate";
import LoanAtHomeServiceIcon from "assets/LandingPage/PromiseSection/LoanAtHomeService";
import LowestInterestRateIcon from "assets/LandingPage/PromiseSection/LowestInterestRate";
import MinimalDocumentationIcon from "assets/LandingPage/PromiseSection/MinimalDocumentation";
import NoFeesIcon from "assets/LandingPage/PromiseSection/NoFees";
import QuickReleaseGuaranteeIcon from "assets/LandingPage/PromiseSection/QuickReleaseGuarantee";

import IconText from "./styles/IconText";
import IconContainer from "./styles/IconContainer";
import IconWrapper from "./styles/IconWrapper";
import TitleContainer from "./styles/TitleContainer";
import { useTheme } from "@emotion/react";

const PromiseSection = (): JSX.Element => {
  const theme = useTheme() as any;
  const icons: Texts[] = [
    {
      Icon: HighestGramPerRateIcon,
      text: "Highest Per Gram Rate",
    },
    {
      Icon: LowestInterestRateIcon,
      text: "Lowest Interest Rate",
    },
    {
      Icon: LoanAtHomeServiceIcon,
      text: "Loan At Home Service",
    },
    {
      Icon: QuickReleaseGuaranteeIcon,
      text: "Quick Release Guarantee",
    },
    {
      Icon: MinimalDocumentationIcon,
      text: "Minimal Documentation",
    },
    {
      Icon: NoFeesIcon,
      text: "No Processing Fee",
    },
  ];

  return (
    <Section theme={theme}>
      <Container left>
        <TitleContainer>
          <PrimaryTitle>Our promise</PrimaryTitle>
        </TitleContainer>
      </Container>
      <Container>
        <IconWrapper>
          {icons.map((icon, i) => {
            return (
              <IconContainer key={icon.text}>
                {<icon.Icon />}
                <IconText theme={theme}>{icon.text}</IconText>
              </IconContainer>
            );
          })}
        </IconWrapper>
      </Container>
    </Section>
  );
};

interface Texts {
  Icon: () => JSX.Element;
  text: string;
}

export default PromiseSection;
