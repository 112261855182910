import styled from "@emotion/styled";

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export default TitleContainer;
