import styled from "@emotion/styled";

const IconHolder = styled.a`
  margin-right: 1rem;
  align-self: baseline;

  & > svg {
    height: 1.9vw;
    width: 1.9vw;

    min-height: 1.8rem;
    min-width: 1.8rem;
  }
`;

export default IconHolder;
