import Container from "./styles/Container";
import LeftSection from "./styles/LeftSection";
import Heading from "./styles/Heading";
import ImageContainer from "./styles/ImageContainer";
import { SHIVALIK, ICICI, NEWINDIA, LINKSMART, HDFC, Piramal } from "./Images";
import ImageHolder from "./styles/ImageHolder";
import RightSection from "./styles/RightSection";
import { useTheme } from "@emotion/react";

const PartnerSection = () => {
  const theme = useTheme() as any;
  const lendingPartnerImage = [
    {
      link: HDFC,
    },
    {
      link: SHIVALIK,
    },
    // {
    //   link: Piramal,
    // },
  ];

  const insurancePartnerImage = [
    {
      link: ICICI,
    },
    {
      link: NEWINDIA,
    },
    {
      link: LINKSMART,
    },
  ];

  return (
    <Container>
      <LeftSection>
        <Heading theme={theme}>LENDING PARTNER</Heading>
        <ImageContainer>
          {lendingPartnerImage.map((img, i) => (
            <ImageHolder style={{ marginBottom: "0.5rem" }} key={i}>
              <img src={img.link} alt={"image-" + img.link + i} />
            </ImageHolder>
          ))}
        </ImageContainer>
      </LeftSection>

      <RightSection>
        <Heading theme={theme}>INSURANCE & OTHER PARTNERS</Heading>
        <ImageContainer>
          {insurancePartnerImage.map((img, i) => (
            <ImageHolder key={"partner-image-" + i}>
              <img src={img.link} alt={"image-" + img.link + i} />
            </ImageHolder>
          ))}
        </ImageContainer>
      </RightSection>
    </Container>
  );
};

export default PartnerSection;
