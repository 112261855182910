import React from "react";

const Linkedin = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g fill="#2E709D">
            <path
              d="M17 0H1C.448 0 0 .448 0 1v16c0 .552.448 1 1 1h16c.552 0 1-.448 1-1V1c0-.552-.448-1-1-1zM5.339 15.337H2.667v-8.59h2.672v8.59zM4.003 5.574c-.855 0-1.548-.693-1.548-1.548 0-.855.693-1.548 1.548-1.548.855 0 1.548.693 1.548 1.548 0 .855-.693 1.548-1.548 1.548zm11.335 9.763h-2.669V11.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H7.013v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71h.001z"
              transform="translate(-48 -1107) translate(48 1107) translate(3 3)"
            />
          </g>
          <path
            d="M0 0H24V24H0z"
            transform="translate(-48 -1107) translate(48 1107)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Linkedin;
