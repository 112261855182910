import Container from "./styles/Container";
import ImageContainer from "./styles/ImageContainer";
import StepContainer from "./styles/StepContainer";
import StepsSection from "./styles/StepsSection";
import SubTextHolder from "./styles/SubTextHolder";
import TextHolder from "./styles/TextHolder";
import { images } from "./Images";
import DesktopContainer from "./styles/DesktopVisible";
import MobileVisible from "./styles/MobileVisible";
import { useState } from "react";
import IconsContainer from "./styles/IconsContainer";
import IconWrapper from "./styles/IconWrapper";
import LeftIcon from "../../assets/LandingPage/StepsSection/LeftIcon";
import RightIcon from "../../assets/LandingPage/StepsSection/RightIcons";
import { useTheme } from "@emotion/react";
import QuoteSection from "components/QuoteSection";
import MiniDivider from "./styles/MiniDivider";

const { How1, How2, How3, How4 } = images;

const StepSection = () => {
  const theme = useTheme() as any;
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      image: How1,
      subHeader: "Request for a new loan",
      text: "Click on “Apply Now” above to request for a new loan. Our loan advisor will call you back and guide you through the schemes",
    },
    {
      image: How2,
      subHeader: "Get doorstep service",
      text: "A Loan Manager will come to your home itself and process your loan application. The entire process takes less than 30 minutes.",
    },
    {
      image: How3,
      subHeader: "Money is transferred instantly",
      text: "Money is transferred to your bank account instantly after the application is processed. Your gold ornaments are packed in front of your eyes and sealed in secure GPS enabled packets.",
    },
    {
      image: How4,
      subHeader: "Gold stored in bank vaults",
      text: "After the money has been transferred to your account, the Loan Manager safely takes your gold and deposits it in secure lockers. Your gold is insured and our responsibility the minute it leaves your house.",
    },
  ];

  const onLeftClick = () => {
    if (currentStep !== 0) {
      setCurrentStep((step) => step - 1);
    }
  };

  const onRightClick = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((step) => step + 1);
    }
  };

  return (
    <Container theme={theme}>
      <QuoteSection textAlign="center">
        Get gold loan in 4 simple steps
      </QuoteSection>
      <MiniDivider />

      <MobileVisible>
        <StepContainer>
          <ImageContainer>
            <img src={steps[currentStep].image} alt={"image-" + currentStep} />
          </ImageContainer>
          <SubTextHolder>{steps[currentStep].subHeader}</SubTextHolder>
          <TextHolder>{steps[currentStep].text}</TextHolder>

          <IconsContainer>
            <IconWrapper onClick={onLeftClick}>
              <RightIcon isActive={!(currentStep === 0)} />
            </IconWrapper>
            <IconWrapper onClick={onRightClick}>
              <LeftIcon isActive={currentStep < steps.length - 1} />
            </IconWrapper>
          </IconsContainer>
        </StepContainer>
      </MobileVisible>

      <DesktopContainer>
        <StepsSection theme={theme}>
          {steps.map((step, i) => (
            <StepContainer key={"step-" + i}>
              <ImageContainer>
                <img src={step.image} alt={"image-" + i} />
              </ImageContainer>
              <SubTextHolder>{step.subHeader}</SubTextHolder>
              <TextHolder>{step.text}</TextHolder>
            </StepContainer>
          ))}
        </StepsSection>
      </DesktopContainer>
    </Container>
  );
};

export default StepSection;
