import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}

const Container = styled.div<Props>`
  margin-top: 88px;
  display: flex;
  background: ${({ theme }) => theme.background.goldLight};

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    height: calc(53vw - 6rem);
  }
`;

export default Container;
