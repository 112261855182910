import styled from '@emotion/styled'
import { ThemeProps } from 'Theme/theme'

const IconContainer = styled.div<{ theme: ThemeProps }>`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  white-space: normal;

  align-items: center;

  /* svg {
    min-width: 15px;
    min-height: 15px;
    max-width: 15px;
    max-height: 15px;
    margin-right: 5px;

    path {
      fill: ${({ theme }) => theme.color.primary};
    }
  } */
`

export default IconContainer
