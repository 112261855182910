import styled from "@emotion/styled";

const TextHolder = styled.div`
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    padding: 0 3rem;
    margin: 1rem 0;
  }

  @media (min-width: 768px) {
    font-size: 1vw;
    line-height: 1.5;
    margin-top: 1rem;
    text-align: left;
    padding: 0;
  }
`;

export default TextHolder;
