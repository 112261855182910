import React from "react";

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="176"
    height="40"
    viewBox="0 0 176 40"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <path
            fill="#121212"
            d="M3.08 4.366c1.695 0 3.071 1.371 3.078 3.067.007 1.696-1.358 3.078-3.054 3.091C1.408 10.538.021 9.18 0 7.483-.011 6.66.309 5.866.887 5.28c.58-.586 1.369-.915 2.192-.914zM.6 32.008V13.517h4.996v18.49H.6zM15.925 32.008h-4.982V13.517h4.83v2.29c1.133-1.916 3.344-2.78 5.336-2.78 4.582 0 6.683 3.267 6.683 7.324v11.657h-4.996V21.23c0-2.064-1.011-3.68-3.396-3.68-2.177 0-3.457 1.688-3.457 3.831l-.018 10.627zM50.48 28.68c0 1.237.075 2.588.15 3.339h-4.77c-.123-.707-.186-1.422-.188-2.14-.827 1.442-2.706 2.574-5.181 2.574-5.26 0-9.016-4.151-9.016-9.728 0-5.408 3.642-9.653 8.865-9.653 3.192 0 4.694 1.317 5.218 2.29V4.796h4.906l.015 23.883zM41.09 28c2.555 0 4.529-1.95 4.529-5.283 0-3.332-1.989-5.143-4.528-5.143-2.54 0-4.582 1.886-4.582 5.18 0 3.295 1.951 5.235 4.582 5.235V28zM58.366 4.366c1.696 0 3.072 1.371 3.08 3.067.006 1.696-1.36 3.078-3.055 3.091-1.696.014-3.083-1.345-3.104-3.041-.011-.823.308-1.617.887-2.203.579-.586 1.368-.915 2.192-.914zm-2.48 27.642V13.517h4.997v18.49h-4.996zM70.611 21.381l4.529-.675c1.052-.151 1.388-.676 1.388-1.317 0-1.314-1.011-2.404-3.117-2.404-2.177 0-3.396 1.392-3.528 3.019l-4.434-.94c.302-2.894 2.97-6.087 7.925-6.087 5.86 0 8.037 3.306 8.037 7.027v9.075c.006.98.082 1.96.227 2.929h-4.566c-.13-.732-.192-1.473-.189-2.216-.94 1.465-2.706 2.744-5.445 2.744-3.944 0-6.347-2.668-6.347-5.559 0-3.305 2.456-5.147 5.52-5.596zm5.932 3.147v-.819l-4.15.638c-1.28.189-2.295.902-2.295 2.328 0 1.091.789 2.144 2.404 2.144 2.09 0 4.041-1.015 4.041-4.29z"
            transform="translate(-136 -24) translate(136 24)"
          />
          <path
            fill="#CE9F29"
            d="M92.672 31.823c.336 2.026 1.95 3.49 4.317 3.49 3.154 0 4.905-1.577 4.905-5.143v-1.351c-.754 1.2-2.479 2.366-5.147 2.366-4.905 0-8.6-3.796-8.6-8.977.015-4.883 3.551-9 8.615-9 2.932 0 4.623 1.275 5.26 2.517v-2.193h4.808V30.02c0 5.072-2.743 9.69-9.69 9.69-5.072 0-8.45-3.154-8.978-6.686l4.51-1.2zm9.313-9.615c0-2.857-1.955-4.657-4.359-4.657-2.479 0-4.43 1.8-4.43 4.657 0 2.856 1.842 4.694 4.43 4.694 2.59 0 4.359-1.842 4.359-4.694zM129.89 22.77c0 5.66-4.15 9.811-9.69 9.811-5.54 0-9.69-4.132-9.69-9.811 0-5.68 4.15-9.812 9.69-9.812 5.54 0 9.69 4.102 9.69 9.812zm-4.996 0c0-3.495-2.264-5.257-4.694-5.257s-4.694 1.762-4.694 5.257c0 3.494 2.264 5.256 4.694 5.256s4.706-1.762 4.706-5.256h-.012zM133.834 32.008L133.834 4.838 138.83 4.838 138.83 32.008zM161.777 28.68c0 1.237.076 2.588.151 3.339h-4.77c-.123-.707-.186-1.422-.188-2.14-.827 1.442-2.702 2.574-5.181 2.574-5.26 0-9.015-4.151-9.015-9.728 0-5.408 3.641-9.653 8.864-9.653 3.192 0 4.694 1.317 5.219 2.29V4.796h4.905l.015 23.883zM152.39 28c2.554 0 4.528-1.95 4.528-5.283 0-3.332-1.989-5.143-4.528-5.143-2.54 0-4.581 1.886-4.581 5.18 0 3.295 1.95 5.235 4.58 5.235V28z"
            transform="translate(-136 -24) translate(136 24)"
          />
          <path
            fill="#121212"
            d="M167.713.619L167.713 4.819 167.049 4.819 167.049.619 165.438.619 165.438 0 169.325 0 169.325.619zM174.547 4.819L174.547 1.068 172.906 4.819 172.294 4.819 170.664 1.068 170.664 4.819 170.004 4.819 170.004 0 170.906 0 172.604 3.921 174.317 0 175.208 0 175.208 4.819z"
            transform="translate(-136 -24) translate(136 24)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
