import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import Container from "./styles/Container";
import LeftSection from "./styles/LeftSection";
import RightSection from "./styles/RightSection";
import ImageWrapper from "./styles/ImageWrapper";
import QuoteSection from "components/QuoteSection";
import FormSection from "./styles/FormSection";
import SubHeader from "./styles/SubHeader";
import WhyChooseSection from "./styles/WhyChooseSection";
import ReasonsContainer from "./styles/ReasonsContainer";
import ReasonHolder from "./styles/ReasonHolder";
import SuccessIcon from "assets/SocialMediaIcons/SuccessIcon";
import FormHolder from "./styles/Form";
import InfoHolder from "./styles/InfoHolder";
import InputBox from "./styles/InputBox";
import SubmitButton from "./styles/SubmitButton";
import ImageHolder from "./styles/ImageHolder";
import ErrorTextContainer from "./styles/ErrorTextContainer";
import InputContainer from "./styles/InputContainer";

import HeroImageDesktop from "assets/LandingPage/HeroSection/hero_banner_desktop.png";
import HeroImageMobile from "assets/LandingPage/HeroSection/hero_banner_mweb.png";

import IconHolder from "components/Footer/ForthBox/styles/IconHolder";
import ModalComponent from "components/HeroSection/Modal";
import ReactPixel from "react-facebook-pixel";

import createLead from "api/herosection/createLead";
import sendOtp from "api/herosection/sentOTP";
import AppActions from "types/actions";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators, Dispatch } from "redux";
import { AlertProps } from "@material-ui/lab/Alert/Alert";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { CircularProgress, IconButton, Portal } from "@material-ui/core";

import SuccessModal from "./SuccessModal";
import PaperComponent from "./Paper";

import createIFrameWithPixel from "lib/createIFrameWithPixel";
import OTPModal from "components/OTPModal";
import verifyOtp from "api/herosection/verifyotp";
import verifyLandingPageLead from "api/herosection/verifyLead";
import validateMobileNumber from "lib/validateMobileNumber";
import onGaHandler from "lib/onGaHandler";

import { ErrorStartAction } from "actions/status";
import { useMemo } from "react";
import TagManager from "react-gtm-module";
import { useTheme } from "@emotion/react";
import { DEFAULT_RATE } from "lib/constant";

const HeroSection: FC<Props> = ({ displayError }: Props): JSX.Element => {
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobilleNumber] = useState("");
  const [pin, setPin] = useState("");
  const [errors, setErrors] = useState({
    fullNameError: "",
    mobileError: "",
    pincodeError: "",
  });
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [sendingReq, setSendingReq] = useState(false);
  const [currentUTMSource, setCurrentUTMSource] = useState<string | null>("");

  const utmRef = useRef<UTMRef>({
    utmSource: "",
    utmMedium: "",
    utmCampaign: "",
    utmDetail: "",
    utmTerm: "",
    utmContent: "",
  });

  const queryParams = useLocation()?.search;

  useEffect(() => {
    if (queryParams) {
      const urlParams = new URLSearchParams(queryParams);

      setCurrentUTMSource(
        urlParams.get("utm_source")?.toUpperCase() ||
          urlParams.get("attribution_type")?.toUpperCase() ||
          ""
      );
    }
  }, [queryParams]);

  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [otp, setOtp] = useState("");
  const [otpId, setOtpId] = useState("");

  const reasons = useMemo(
    () => [
      "Free doorstep service without any charges",
      "Make loan repayments online using UPI, Debit Card and Net Banking",
      "Flexible schemes based on your needs",
    ],
    []
  );

  const crossOTPClickHandler = () => setOpenOTPModal((isOpen) => !isOpen);
  const crossSuccessClickHandler = () => setOpenModal((isOpen) => !isOpen);

  const tagManagerArgsGTM = useMemo(() => {
    return {
      dataLayer: {
        event: "lead",
        Name: fullName,
        Mobile: mobileNumber,
        Pincode: pin,
        Source: "goldloans.indiagold.co",
      },
    };
  }, [fullName, mobileNumber, pin]);

  const sendOTP = useCallback(async () => {
    setSendingReq(true);
    const {
      payload: sendOtpPayload,
      error: sendOtpError,
      statusCode: sendOtpStatusCode,
    } = await sendOtp({
      mobile: mobileNumber,
    });

    if (sendOtpStatusCode === 200) {
      setOtpId(sendOtpPayload?.otpId || "");
      setOpenOTPModal(true);
    } else {
      displayError(
        sendOtpError || "Something went wrong while sending OTP.",
        "error"
      );
    }
    setSendingReq(false);
  }, [displayError, mobileNumber]);

  const handleApplyNowClick = useCallback(async () => {
    onGaHandler("apply now");
    if (!validateMobileNumber(mobileNumber)) {
      displayError("Invalid Mobile Number", "error");
      return;
    }
    try {
      const urlParams = new URLSearchParams(queryParams);

      if (!!queryParams && !!urlParams) {
        utmRef.current = {
          utmCampaign: urlParams.get("utm_campaign"),
          utmSource:
            urlParams.get("utm_source") || urlParams.get("attribution_type"),
          utmMedium: urlParams.get("utm_medium"),
          utmDetail: urlParams.get("utm_detail") || urlParams.get("code"),
          utmTerm: urlParams.get("utm_term"),
          utmContent: urlParams.get("utm_content"),
        };
      }

      setSendingReq(true);
      const { payload, error, statusCode } = await createLead({
        pincode: parseInt(pin),
        name: fullName,
        mobile: mobileNumber,
        // company: "SVG",
        utmSource:
          utmRef.current.utmSource?.toUpperCase() || utmRef.current.utmSource,
        utmCampaign: utmRef.current.utmCampaign,
        utmMedium: utmRef.current.utmMedium,
        utmDetail: utmRef.current.utmDetail,
        utmTerm: utmRef.current.utmTerm,
        utmContent: utmRef.current.utmContent,
      });

      if (statusCode === 200 && payload !== null) {
        setSendingReq(false);
        sendOTP();
      } else {
        setSendingReq(false);
        displayError(error ?? "Something went wrong on our side", "error");
      }
    } catch (err) {
      setSendingReq(false);
      displayError("Something went wrong", "error");
    }
  }, [mobileNumber, displayError, queryParams, pin, fullName, sendOTP]);

  const onClickOtpVerify = useCallback(async () => {
    setSendingReq(true);
    const { error, statusCode } = await verifyOtp({
      mobile: mobileNumber,
      otp,
      otpId: otpId,
    });

    if (statusCode === 200) {
      const {
        payload: verifyPayload,
        error: verifyError,
        statusCode: verifyStatusCode,
      } = await verifyLandingPageLead({
        name: fullName,
        mobile: mobileNumber,
        pincode: pin,
        utmSource:
          utmRef.current.utmSource?.toUpperCase() || utmRef.current.utmSource,
        utmMedium: utmRef.current.utmMedium,
        utmCampaign: utmRef.current.utmCampaign,
        utmDetail: utmRef.current.utmDetail,
        utmTerm: utmRef.current.utmTerm,
        utmContent: utmRef.current.utmContent,
        otpId,
        otp,
      });

      if (verifyStatusCode === 200) {
        ReactPixel.track("Lead", {
          Name: fullName,
          Mobile: mobileNumber,
          Pincode: pin,
          Source: "goldloans.indiagold.co",
        });

        TagManager.dataLayer(tagManagerArgsGTM);

        if (verifyPayload) setModalText(verifyPayload.status);
        crossOTPClickHandler();
        crossSuccessClickHandler();
        setFullName("");
        setMobilleNumber("");
        setPin("");
      } else {
        displayError(
          verifyError || "Something went wrong while verification",
          "error"
        );

        return;
      }

      if (
        utmRef.current.utmDetail === "SVG" &&
        utmRef.current.utmSource === "Affiliates"
      ) {
        const iFrame = createIFrameWithPixel(
          `https://svg.s2d6.com/pixel?adid=60add396ed1c96240c1abc29&txn_id=${verifyPayload?.id}`
        );
        verifyPayload?.id !== null &&
          utmRef.current.utmDetail === "SVG" &&
          document.body.appendChild(iFrame);
      }
      setOtp("");
    } else {
      displayError(
        error || "Something went wrong while verifying OTP",
        "error"
      );
    }
    setSendingReq(false);
  }, [
    mobileNumber,
    otp,
    otpId,
    fullName,
    pin,
    tagManagerArgsGTM,
    displayError,
  ]);

  const onBlurFullNameHandler = useCallback(() => {
    onGaHandler("full name");
    if (fullName.length === 0) {
      setErrors({
        ...errors,
        fullNameError: "Full name should not be empty.",
      });
    } else if (fullName.length < 3) {
      setErrors({
        ...errors,
        fullNameError: "Full name length should have atleast 3 letters.",
      });
    } else {
      setErrors({
        ...errors,
        fullNameError: "",
      });
    }
  }, [errors, fullName.length]);

  const onBlurMobileHandler = useCallback(() => {
    onGaHandler("mobile number");
    if (mobileNumber.length < 10) {
      setErrors({
        ...errors,
        mobileError: "Mobile number must be of 10 digits.",
      });
    } else if (!validateMobileNumber(mobileNumber)) {
      setErrors({
        ...errors,
        mobileError: "Please enter valid mobile number.",
      });
    } else {
      setErrors({
        ...errors,
        mobileError: "",
      });
    }
  }, [errors, mobileNumber]);

  const onBlurPincodeHandler = useCallback(() => {
    onGaHandler("pincode");
    if (pin.length < 6) {
      setErrors({
        ...errors,
        pincodeError: "Please enter valid pincode (length must be 6)",
      });
    } else {
      setErrors({
        ...errors,
        pincodeError: "",
      });
    }
  }, [errors, pin.length]);

  const onChangeFullNameHandler = useCallback(
    (event: any) =>
      !/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(event.target.value) &&
      !/\d/.test(event.target.value)
        ? setFullName(event.target.value.trimLeft())
        : "",
    []
  );
  const onChangeMobileHandler = useCallback(
    (event: any) =>
      setMobilleNumber(
        event.target.value.length <= 10
          ? event.target.value.trim()
          : mobileNumber
      ),
    [mobileNumber]
  );
  const onChangePinHandler = useCallback(
    (event: any) =>
      setPin(
        (event.target.value.length <= 6 &&
          /^[1-9]{1}/.test(event.target.value)) ||
          event.target.value === ""
          ? event.target.value.trim()
          : pin
      ),
    [pin]
  );

  const theme = useTheme() as any;

  return (
    <Container theme={theme}>
      <LeftSection>
        <QuoteSection theme={theme}>
          Gold Loans starting at just {DEFAULT_RATE}%* monthly interest
        </QuoteSection>
        <FormSection theme={theme}>
          <WhyChooseSection>
            <SubHeader theme={theme}>WHY CHOOSE INDIAGOLD?</SubHeader>
            <ReasonsContainer>
              {reasons.map((reason, i) => (
                <ReasonHolder key={"reason-" + i}>
                  <IconHolder>
                    <SuccessIcon />
                  </IconHolder>
                  <p>{reason}</p>
                </ReasonHolder>
              ))}
            </ReasonsContainer>
          </WhyChooseSection>
          <FormHolder>
            <InputContainer
              theme={theme}
              displays={!!errors.fullNameError.length}
            >
              <InputBox
                theme={theme}
                placeholder="Full name"
                value={fullName}
                onChange={onChangeFullNameHandler}
                onBlur={onBlurFullNameHandler}
                displays={!!errors.fullNameError.length}
              />
              <ErrorTextContainer displays={!!errors.fullNameError.length}>
                {errors.fullNameError}
              </ErrorTextContainer>
            </InputContainer>
            <InputContainer
              theme={theme}
              displays={!!errors.mobileError.length}
            >
              <InputBox
                theme={theme}
                placeholder="Mobile number"
                value={mobileNumber}
                onChange={onChangeMobileHandler}
                type="number"
                maxLength={10}
                onBlur={onBlurMobileHandler}
                displays={!!errors.mobileError.length}
              />
              <ErrorTextContainer displays={!!errors.mobileError.length}>
                {errors.mobileError}
              </ErrorTextContainer>
            </InputContainer>
            {currentUTMSource?.toUpperCase() !== "FACEBOOK" &&
              currentUTMSource?.toUpperCase() !== "GOOGLE" && (
                <InputContainer
                  theme={theme}
                  displays={!!errors.pincodeError.length}
                >
                  <InputBox
                    theme={theme}
                    placeholder="PIN code"
                    value={pin}
                    onChange={onChangePinHandler}
                    type="number"
                    maxLength={6}
                    onBlur={onBlurPincodeHandler}
                    displays={!!errors.pincodeError.length}
                  />
                  <ErrorTextContainer displays={!!errors.pincodeError.length}>
                    {errors.pincodeError}
                  </ErrorTextContainer>
                </InputContainer>
              )}
            <SubmitButton
              theme={theme}
              onClick={handleApplyNowClick}
              disabled={
                (currentUTMSource &&
                "GOOGLEFACEBOOK".includes(
                  currentUTMSource?.toUpperCase() as string
                )
                  ? false
                  : pin.length < 6) ||
                mobileNumber.length < 10 ||
                fullName.length < 3 ||
                sendingReq
              }
            >
              APPLY NOW
              {sendingReq ? (
                <CircularProgress
                  size={14}
                  style={{ color: "white", marginLeft: "10px" }}
                  disableShrink
                />
              ) : null}
            </SubmitButton>
            <InfoHolder theme={theme}>
              By submitting the above information, you allow indiagold’s
              representatives to contact you
            </InfoHolder>
          </FormHolder>
        </FormSection>
      </LeftSection>
      <RightSection>
        <ImageWrapper className="">
          <ImageHolder desktop={HeroImageDesktop} mobile={HeroImageMobile} />
        </ImageWrapper>
      </RightSection>

      <Portal>
        <OTPModal
          otp={otp}
          setOtp={setOtp}
          onCrossClick={crossOTPClickHandler}
          mobileNumber={mobileNumber}
          onClickOtpVerify={onClickOtpVerify}
          onClickResend={sendOTP}
          openOTPModal={openOTPModal}
          disableButton={sendingReq}
        />
      </Portal>

      <Portal>
        <ModalComponent open={openModal}>
          <PaperComponent>
            <SuccessModal
              onCrossClick={crossSuccessClickHandler}
              modalText={modalText}
            />
          </PaperComponent>
        </ModalComponent>
      </Portal>
    </Container>
  );
};

type UTMRef = {
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmDetail: string | null;
  utmTerm: string | null;
  utmContent: string | null;
};

interface DispatchProps {
  displayError: (
    error: string,
    severity: AlertProps["severity"]
  ) => (dispatch: Dispatch<AppActions>) => void;
}

type Props = DispatchProps;

const mapDispatchToProps = (
  dispatch: ThunkDispatch<unknown, unknown, AppActions>
): DispatchProps => ({
  displayError: bindActionCreators(ErrorStartAction, dispatch),
});

export default connect(null, mapDispatchToProps)(HeroSection);
