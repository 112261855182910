import React, { useCallback } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";

import { AppState } from "reducers";
import { Status } from "types/reducers/status";
import AppActions from "types/actions";

import Snackbar from "components/Snackbar";
import { ErrorResetAction, SuccessResetAction } from "actions/status";

const ErrorWrapper = (props: Props): JSX.Element => {
  const { status, closeError, children, closeSuccess } = props;

  const onErrorCloseHandler = useCallback(() => {
    if (status.success) {
      closeSuccess();
    } else {
      closeError();
    }
  }, [closeError, closeSuccess, status]);

  return (
    <>
      {children}
      <Snackbar
        open={status.error || status.success}
        message={status.message}
        severity={status.severity}
        setClose={onErrorCloseHandler}
      />
    </>
  );
};

interface mapStateToProps {
  status: Status;
}

interface DispatchProps {
  closeError: () => void;
  closeSuccess: () => void;
}

interface Props extends DispatchProps {
  status: Status;
  children: React.ReactNode;
}

const mapStateToProp = (state: AppState): mapStateToProps => ({
  status: {
    success: state.status.success,
    error: state.status.error,
    message: state.status.message,
    severity: state.status.severity,
  },
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<unknown, unknown, AppActions>
): DispatchProps => ({
  closeError: bindActionCreators(ErrorResetAction, dispatch),
  closeSuccess: bindActionCreators(SuccessResetAction, dispatch),
});

export default connect(mapStateToProp, mapDispatchToProps)(ErrorWrapper);
