import styled from "@emotion/styled";
import { ThemeProps } from "Theme/theme";

interface Props {
  theme: ThemeProps;
}

const InfoHolder = styled.div<Props>`
  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: center;
    margin-top: 1rem;
    color: ${({ theme }) => theme.color.headerColor};
  }
  @media (min-width: 768px) {
    text-align: center;
    font-size: 0.8vw;
    line-height: 1.5;
    margin-top: 1vw;
    color: ${({ theme }) => theme.color.headerColor};
  }
`;

export default InfoHolder;
